.css-xd4mtb-MuiGrid-root > .MuiGrid-item {
  padding-left: 0;
  padding-top: 0;
}
::-webkit-scrollbar {
  width: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d7d7;
  /* background: #f1f1f1; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d9d7d7;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
select:-webkit-autofill,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: rgb(37, 37, 37) !important;
}
::-ms-reveal {
  display: none;
}
.react-flow__attribution {
  display: none;
}

.MuiTooltip-tooltip {
  background: #d1c4e9;
  color: #172258;
}


* html .clearfix { height: 1%; }